// src/Components/CharacterController.js
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import '../Style/CharacterController.css';

const CharacterController = ({ onMenuSelect, paused }) => {
  console.log('CharacterController rendered. paused=', paused);

  const characterSize = 40;
  const moveDistance = 15;

  const [position, setPosition] = useState({
    x: window.innerWidth / 2, // horisontellt centrerad
    y: 150, // testa 120–180 för bra startpunkt
  });
  
  const [direction, setDirection] = useState('up');
  const [highlighted, setHighlighted] = useState(null);

  // Refs to each menu item
  const aboutRef = useRef(null);
  const eduRef = useRef(null);
  const expRef = useRef(null);
  const contactRef = useRef(null);

  const menuRefs = useMemo(() => ({
    about: aboutRef,
    education: eduRef,
    experience: expRef,
    contact: contactRef,
  }), []);
  

  const getZRotation = (dir) => {
    switch (dir) {
      case 'right': return 90;
      case 'down': return 180;
      case 'left': return 270;
      default: return 0; // up
    }
  };

  const move = useCallback((dir) => {
      console.log('move() triggered with dir=', dir, ' paused=', paused);
      if (paused) return; // om det är paus = avbryt
      setDirection(dir);
      setPosition((prev) => {
        const newPos = { ...prev };
        switch (dir) {
          case 'up':
            newPos.y = Math.max(characterSize / 2, prev.y - moveDistance);
            break;
          case 'down':
            newPos.y = Math.min(window.innerHeight - characterSize / 2, prev.y + moveDistance);
            break;
          case 'left':
            newPos.x = Math.max(characterSize / 2, prev.x - moveDistance);
            break;
          case 'right':
            newPos.x = Math.min(window.innerWidth - characterSize / 2, prev.x + moveDistance);
            break;
          default:
            break;
        }
        console.log('new position=', newPos);
        return newPos;
      });
    }, [paused, characterSize, moveDistance]);

  // Kollisionskoll (vilken meny "träffas"?)
  useEffect(() => {
    const checkOverlap = () => {
      let matched = null;
      Object.entries(menuRefs).forEach(([key, ref]) => {
        const el = ref.current;
        if (el) {
          const rect = el.getBoundingClientRect();
          const charLeft = position.x - characterSize / 2;
          const charRight = position.x + characterSize / 2;
          const charTop = position.y - characterSize / 2;
          const charBottom = position.y + characterSize / 2;

          if (
            charLeft < rect.right &&
            charRight > rect.left &&
            charTop < rect.bottom &&
            charBottom > rect.top
          ) {
            matched = key;
          }
        }
      });
      console.log('checkOverlap => matched=', matched);
      setHighlighted(matched);
    };
    checkOverlap();
  }, [position, menuRefs]);

  // Lyssnar på WASD och Enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      console.log('handleKeyPress:', e.key, ' paused=', paused, ' highlighted=', highlighted);
      if (paused) return;
      switch (e.key) {
        case 'ArrowUp':
        case 'w': move('up'); break;
        case 'ArrowDown':
        case 's': move('down'); break;
        case 'ArrowLeft':
        case 'a': move('left'); break;
        case 'ArrowRight':
        case 'd': move('right'); break;
        case 'Enter':
          if (highlighted) {
            console.log('ENTER pressed, calling onMenuSelect:', highlighted);
            onMenuSelect(highlighted);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [highlighted, paused, move, onMenuSelect]);

  return (
    <div className="character-container">
      <header className="portfolio-header">
        <nav className="nav-links">
          <div ref={aboutRef} className={`nav-item about ${highlighted === 'about' ? 'active' : ''}`}>
            <span>About Me</span>
          </div>
          <div ref={eduRef} className={`nav-item education ${highlighted === 'education' ? 'active' : ''}`}>
            <span>Education</span>
          </div>
          <div ref={expRef} className={`nav-item experience ${highlighted === 'experience' ? 'active' : ''}`}>
            <span>Experience</span>
          </div>
          <div ref={contactRef} className={`nav-item contact ${highlighted === 'contact' ? 'active' : ''}`}>
            <span>Contact Me</span>
          </div>
        </nav>
      </header>

      {/* Om vi står på en meny */}
      {highlighted && (
        <div
          className="menu-activate-button"
          onClick={() => {
            console.log('CLICK on menu-activate-button => onMenuSelect:', highlighted);
            onMenuSelect(highlighted);
          }}
        >
          PRESS ENTER / CLICK
        </div>
      )}

      {/* Karaktär */}
      <div
        className="character"
        style={{
          left: position.x,
          top: position.y,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <img
          src="/charactar.svg"
          alt="My Character"
          className="character-image"
          style={{
            transform: `rotateZ(${getZRotation(direction)}deg)`,
            transition: 'transform 0.2s ease'
          }}
        />
      </div>

      {/* Pilknappar */}
      <div className="controls">
        <div className="arrow-grid">
          <button className="control-button up" onClick={() => move('up')}>↑</button>
          <button className="control-button left" onClick={() => move('left')}>←</button>
          <button className="control-button down" onClick={() => move('down')}>↓</button>
          <button className="control-button right" onClick={() => move('right')}>→</button>
        </div>
      </div>

      <footer className="footer-info">
        <p>
          💡 You can use <strong>W A S D</strong> or the <strong>Arrow Keys</strong> to move around. <br />
          On mobile? Use the glowing buttons at the bottom. <br />
          <em>Welcome to my jungle portfolio – will you be able to overcome all the challenges and uncover all the info? 😉</em>
        </p>
      </footer>
    </div>
  );
};

export default CharacterController;
