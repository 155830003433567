// src/Components/InfoPanel.js
import React, { useEffect, useState } from 'react';
import '../Style/InfoPanel.css';
import AryaImg from '../images/Arya.jpg';

const InfoPanel = ({ onClose }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // ✅ Förladdar bilden direkt efter mount
  useEffect(() => {
    const img = new Image();
    img.src = AryaImg;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className="about-wrapper">
      <button className="close-btn" onClick={onClose}>✕</button>

      <div className="about-content">
        <img
          src={AryaImg}
          alt="Arya Eisa"
          className={`profile-img ${imageLoaded ? 'loaded' : ''}`}
        />

        <div className="text-info">
          <h2>Hi, I'm Arya 👋</h2>
          <p>
            I'm an engineer with a strong drive for innovation and meaningful impact. Over the years, I've led and collaborated on projects where creativity, structure and purpose go hand-in-hand — whether it's guiding a team, solving real-world problems, or building systems that make a difference.
          </p>
          <p>
            As the founder of <strong>Ophelia</strong>, I helped shape an AI-powered tool designed to detect psychological abuse. With support from my team at Threesoft AB and insights from diverse sectors, the project grew from idea to implementation — combining empathy with technology in a way that truly matters.
          </p>
          <p>
            I have hands-on experience with both backend and frontend development, and I’m comfortable working across the stack. My toolbox includes languages like <strong>Kotlin, Swift, React, C++, and Python</strong>. I enjoy writing clean, scalable code and love the challenge of turning complexity into clarity.
          </p>
          <p>
            Whether I'm leading a team or diving deep into development, I always strive to learn, adapt, and build things that are not only functional — but human-centered.
          </p>

          <a
            href="./AryaCV.pdf"
            download
            className="download-btn"
          >
            📄 Download My CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
