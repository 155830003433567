// src/Components/Education.js
import React, { useState, useEffect } from 'react';
import '../Style/Education.css';
import image1 from '../images/JU.png';
import image2 from '../images/IBMI.png';
import image3 from '../images/Hubspot.png';

const ROWS = 6;
const COLS = 7;

const createBoard = () => Array(ROWS).fill(null).map(() => Array(COLS).fill(null));

const Education = ({ onClose }) => {
  const [board, setBoard] = useState(createBoard());
  const [currentPlayer, setCurrentPlayer] = useState('🔵');
  const [winner, setWinner] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);

  // ✅ Förladda bilder i bakgrunden så de visas direkt efter unlock
  useEffect(() => {
    [image1, image2, image3].forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  // ✅ Win-checker (horisontell, vertikal, diagonal)
  const checkWin = (brd) => {
    for (let r = 0; r < ROWS; r++) {
      for (let c = 0; c < COLS; c++) {
        const p = brd[r][c];
        if (!p) continue;
        if (c + 3 < COLS && p === brd[r][c + 1] && p === brd[r][c + 2] && p === brd[r][c + 3]) return p;
        if (r + 3 < ROWS && p === brd[r + 1][c] && p === brd[r + 2][c] && p === brd[r + 3][c]) return p;
        if (r + 3 < ROWS && c + 3 < COLS && p === brd[r + 1][c + 1] && p === brd[r + 2][c + 2] && p === brd[r + 3][c + 3]) return p;
        if (r + 3 < ROWS && c - 3 >= 0 && p === brd[r + 1][c - 1] && p === brd[r + 2][c - 2] && p === brd[r + 3][c - 3]) return p;
      }
    }
    return null;
  };

  const dropInColumn = (brd, colIndex, symbol) => {
    const newBoard = brd.map(row => [...row]);
    for (let r = ROWS - 1; r >= 0; r--) {
      if (!newBoard[r][colIndex]) {
        newBoard[r][colIndex] = symbol;
        return newBoard;
      }
    }
    return null;
  };

  const botMove = (brd) => {
    const availableCols = Array.from({ length: COLS }, (_, i) => i).filter(col => brd[0][col] === null);
    const choice = availableCols[Math.floor(Math.random() * availableCols.length)];
    return dropInColumn(brd, choice, '🔴');
  };

  const handleDrop = (colIndex) => {
    if (winner || isUnlocked || currentPlayer !== '🔵') return;

    const nextBoard = dropInColumn(board, colIndex, '🔵');
    if (!nextBoard) return;

    const result = checkWin(nextBoard);
    setBoard(nextBoard);
    if (result) {
      setWinner(result);
      if (result === '🔵') setIsUnlocked(true);
      return;
    }

    setCurrentPlayer('🔴');
    setTimeout(() => {
      const botBoard = botMove(nextBoard);
      const botResult = checkWin(botBoard);
      setBoard(botBoard);
      setCurrentPlayer('🔵');
      if (botResult) setWinner(botResult);
    }, 600);
  };

  const reset = () => {
    setBoard(createBoard());
    setCurrentPlayer('🔵');
    setWinner(null);
    setIsUnlocked(false);
  };

  // ✅ För fade-in-effekt när bild är klar
  const handleImageLoad = (e) => {
    e.target.classList.add('loaded');
  };

  return (
    <div className="education-wrapper">
      <button className="close-btn" onClick={onClose}>✕</button>
      <h2>🤖 Beat the Bot to Unlock My Education</h2>
      <p className="instruction">
        Outsmart the AI in Connect Four to unlock my academic journey – only true strategists get access!
      </p>

      {!isUnlocked && (
        <div className="connect-board">
          {board.map((row, rIdx) => (
            <div key={rIdx} className="row">
              {row.map((cell, cIdx) => (
                <div
                  key={cIdx}
                  className={`cell ${cell === '🔵' ? 'player' : cell === '🔴' ? 'bot' : ''}`}
                  onClick={() => handleDrop(cIdx)}
                >
                  <span>{cell || ''}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {winner && !isUnlocked && (
        <div className="game-result">
          <p>{winner === '🔵' ? '🎉 You Win!' : '😵 Bot Wins!'}</p>
          <button onClick={reset}>Play Again</button>
        </div>
      )}

      {isUnlocked && (
        <>
          <p className="congrats-msg">
            🎉 Well done! You've earned the right to explore my education ✨
          </p>

          <div className="education-info">
            <h3>My Education</h3>
            <div className="education-list">
              <div className="edu-card">
                <img src={image1} onLoad={handleImageLoad} alt="Jönköping University" />
                <div className="edu-text">
                  <h4>Software Engineering</h4>
                  <p>Jönköping University</p>
                </div>
              </div>
              <div className="edu-card">
                <img src={image2} onLoad={handleImageLoad} alt="IBMI" />
                <div className="edu-text">
                  <h4>Project Management</h4>
                  <p>International Business Management Institute (IBMI)</p>
                </div>
              </div>
              <div className="edu-card">
                <img src={image3} onLoad={handleImageLoad} alt="HubSpot Academy" />
                <div className="edu-text">
                  <h4>Sales Management</h4>
                  <p>HubSpot Academy</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Education;
